import '../plugin/slick/slick'

$(window).on('load', function() {
  let danceSlider = $('.dance-style__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    rows: 0,
    arrows: false,
    asNavFor: '.dance-style__slider-nav'
  });
  let danceSliderNav =  $('.dance-style__slider-nav').slick({
    rows: 0,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    focusOnSelect: true,
    infinite: false,
    // asNavFor: '.dance-style__slider',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          focusOnSelect: true,
          infinite: true,
          centerMode: true
        }
      }
    ]
  });
})


//промотка к главному слайдеру
$(".dance-style__slider-nav__item").click(function () {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(".dance-style").offset().top
  }, 500);
  $('.dance-style__slider').slick('slickGoTo', $(this).data('slickIndex'));
});
