import "../plugin/hc-offcanvas-nav/hc-offcanvas-nav"

$(document).ready(function ($) {
  $('#js-nav').hcOffcanvasNav({
    maxWidth: 1100,
    customToggle: '#mobnav',
    labelClose:	'Закрыть',
    labelBack: 'Назад'	
  });
});
